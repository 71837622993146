$font-path-montserrat: "../../fonts/montserrat";
// $font-path-inter: "../../fonts/inter/";

@font-face {
  font-family: "inter";
  src: url("../../fonts/inter/InterLight.woff") format("woff"), url("../../fonts/inter/InterLight.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat-regular";
  src: url("#{$font-path-montserrat}/regular/Montserrat-Regular.woff") format("woff"),
    url("#{$font-path-montserrat}/regular/Montserrat-Regular.woff") format("woff2"),
    url("#{$font-path-montserrat}/regular/Montserrat-Regular.ttf") format("ttf"),
    url("#{$font-path-montserrat}/regular/Montserrat-Regular.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat-medium";
  src: url("#{$font-path-montserrat}/medium/Montserrat-Medium.woff") format("woff"),
    url("#{$font-path-montserrat}/medium/Montserrat-Medium.woff") format("woff2"),
    url("#{$font-path-montserrat}/medium/Montserrat-Medium.ttf") format("ttf"),
    url("#{$font-path-montserrat}/medium/Montserrat-Medium.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat-semibolt";
  src: url("#{$font-path-montserrat}/bolt/Montserrat-SemiBold.woff") format("woff"),
    url("#{$font-path-montserrat}/bolt/Montserrat-SemiBold.woff") format("woff2"),
    url("#{$font-path-montserrat}/bolt/Montserrat-SemiBold.ttf") format("ttf"),
    url("#{$font-path-montserrat}/bolt/Montserrat-SemiBold.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}
