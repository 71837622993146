.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  width: 100%;
  margin-bottom: 100px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    max-width: 1600px;
    margin-bottom: 170px;
  }
  &--catalog {
    margin-bottom: 62px;
  }
}
.hero-section {
  width: 100%;
  height: 375px;
  @media screen and (min-width: 768px) {
    width: 50%;
    height: 479px;
  }
  @media screen and (min-width: 1420px) {
    //width: 720px;
    height: 479px;
  }
  background-color: $black;

  &--text {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 10% 10px;
    @media screen and (min-width: 768px) {
      padding: 0 10% 0 10%;
    }
    @media screen and (min-width: 1420px) {
      padding: 0 124px 0 124px;
    }
    p {
      max-height: 220px;
      overflow: hidden;
      margin-bottom: 30px;
      margin-right: 0;
      color: $white;
      font-family: montserrat-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 170%;
    }
    h1 {
      margin-bottom: 20px;
      color: $white;
    }
  }
}
.hero-section-catalog {
  width: 100vw;
  height: 479px;
}
