.slider .slide-container {
    margin-bottom: 15px;
    @media screen and (min-width: 576px) {
        margin-bottom: 0;
    }
}
// .slick-track {
//   max-width: 100% !important;
// }
.container-slide-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
    @media screen and (min-width: 370px) {
        max-width: 295px;
    }

  //width: 293px;
  //@media screen and (max-width: 768px) {
  //  width: 163px;
  //}
}
.card-container{
    //max-width: 295px;
    //min-width: 250px;
    width: calc(100%/2 - 10px);
    margin-inline: 5px;
    @media screen and (min-width: 769px) {
        width: calc(100%/3 - 20px);
        margin-inline: 10px;
    }
    @media screen and (min-width: 992px) {
        margin-inline: 0;
    }
    @media screen and (min-width: 1200px) {
        width: calc(100%/3);
        margin-inline: 0;
    }
    @media screen and (min-width: 1200px) {
        width: calc(100%/4);
    }

}
.container-image {
  //width: 100%;
  //height: 307px;
  //border-bottom: 1px solid $black;
  //margin-bottom: 15px;
    border-bottom: 1px solid $black;
    height: 360px;
    margin-bottom: 15px;
    width: 100%;
    //max-width: 295px;
  @media screen and (max-width: 576px) {
      height: 250px;

  }
  &--catalog {
    @media screen and (max-width: 992px) {
      //height: 183px;
    }
  }
}

.card-action-img {
    width: 29px;
    height: auto;
}

.image-card {
  //object-fit: cover;
  //width: 100%;
  //height: 95%;
  //display: block;
  //margin-bottom: 15px;
    display: block;
    height: 95%;
    max-height: inherit;
    margin-bottom: 15px;
    object-fit: contain;
    width: 100%;
}
.card-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  // margin-bottom: 15px;
}
.text {
  width: 100%;
  padding: 0 10px;
    text-align: left;
}

.card-black-border:hover {
    border: 1px solid #010101 !important;
}

.price {
  font-size: 18px;
}
.card-btn {
  width: 100%;
  background: $black;
  color: $white;
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  transition: all 250ms ease-out;
  &:hover,
  &:focus {
    opacity: 0.7;
    color: $white;
  }
}
.card-btn {
    @media screen and (max-width: 992px) {
        opacity: 1;
    }
  opacity: 0;
  transition: all 250ms ease-out;
  .bordered:hover ~ &,
  .bordered:focus ~ &,
  .card-box:hover ~ &,
  .card-box:focus ~ & {
    opacity: 1;
  }
}
.slick-track {
  margin-left: 0;
  margin-right: 0;
}
.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
  @media screen and (max-width: 768px) {
    top: 30%;
  }
  border-radius: 2px;
  color: black;
  transition: all 250ms ease-out;
  &::before {
    @media screen and (max-width: 575px) {
      display: none;
    }
    transition: all 250ms ease-out;
    color: inherit;
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
  }
  &:hover,
  &:focus {
    color: black;
    &::before {
      transition: all 250ms ease-out;
      color: inherit;
      background-color: black;
      border-color: black;
    }
  }
}

.slick-prev {
  //@media screen and (min-width: 768px) and (max-width: 992px) {
  //  left: 0px;
  //}
  left: -34px;
  &::before {
    background: url("/images/icons/arrow-black.svg") no-repeat center;
  }
  &:hover,
  &:focus {
    color: black;
    &::before {
      background: url("/images/icons/arrow-white-left.svg") no-repeat center;
      color: inherit;
      background-color: black;
      border-color: black;
    }
  }
}
.slick-next {
  //@media screen and (min-width: 768px) and (max-width: 992px) {
  //  right: 0px;
  //}
  right: -34px;
  &::before {
    background: url("/images/icons/arrow-black-right.svg") no-repeat center;
  }
  &:hover,
  &:focus {
    color: black;
    &::before {
      background: url("/images/icons/arrow-slider.svg") no-repeat center;
      color: inherit;
      background-color: black;
      border-color: black;
    }
  }
}
.slick-dotted.slick-slider {
  margin: 0;
}
.slick-list {
  z-index: 3;
}

.bordered {
  transition: all 250ms ease-out;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
    @media screen and (max-width: 992px) {
        border-right: 1px solid black;
        border-left: 1px solid black;
    }
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}
.wrap-btn {
  width: 100%;
  height: 100%;
}
.slick-slide {
  transition: all 250ms ease-out;
  &:hover,
  &:focus {
    .bordered {
      border-right: 1px solid black;
      border-left: 1px solid black;
    }
  }
}
.card-box {
  transition: all 250ms ease-out;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  padding: 0 10px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
    margin-inline: 0;
  @media screen and (max-width: 992px) {
    width: 100%;
      border-right: 1px solid black;
      border-left: 1px solid black;
      margin-inline: 10px;
  }
    //max-width: 295px;

  .card-container:hover &,
  .card-container:focus & {
    border-right: 1px solid black;
    border-left: 1px solid black;
  }
}

.slick-dots {
  position: absolute;
  top: 10%;
  right: 0;
}
.slick-dots li button {
  position: relative;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border: 1px solid var(--secondary-text-color);
  border-radius: 50%;
  font-size: 0;
  outline-style: none;
}
body {
  counter-reset: btn;
}
.slick-dots {
  top: -17%;
}
.slider-page {
  overflow: hidden;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  width: 168px;
  @media screen and (min-width: 992px) {
    width: 292px;
  }
  // padding: 30px 20px 30px 40px;
}
