.margin-right-15 {
  margin-right: 15px;
}
.margin-right-7 {
  margin-right: 7px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-227 {
  margin-right: 227px;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-19 {
  margin-right: 19px;
}
.margin-right-37 {
  margin-right: 37px;
}
.margin-bottom-150 {
  margin-bottom: 150px;
}
.margin-bottom-120 {
  margin-bottom: 120px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-9 {
  margin-bottom: 9px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-19 {
  margin-top: 19px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-top-120 {
  margin-top: 120px;
}
.margin-5 {
  margin: 0 5px;
}
.image-auto {
  height: auto;
}
.padding-40 {
  padding: 40px;
}
