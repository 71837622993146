.footer {
  display: flex;
  justify-content: center;
}
.footer-container {
  padding: 10px 10px;
  @media screen and (min-width: 576px) {
    padding: 25px 50px;
  }
  @media screen and (min-width: 1420px) {
    padding: 25px 10px;
  }
}
.footer-wrapper {
  display: block;
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.contacts {
  display: flex;
  flex-direction: column;
  color: $white;
  justify-content: end;
  margin-bottom: 0;
    align-items: flex-start;
  //@media screen and (min-width: 992px) {
  //  align-items: flex-end;
  //  margin-bottom: 0;
  //}
}
.contacts-item {
 font-family: 'montserrat-regular';
  transition: all 250ms ease-out;
  border-bottom: 1px solid transparent;
  &:hover,
  &:focus {
    color: $white;
    border-bottom: 1px solid $white;
  }
}

.policy {
    text-align: center;
  color: $white;
}
.centered-block{
    margin-left: 60px;
}
.footer_logo {
  display: flex;
  align-items: center;
  align-self: baseline;
    justify-content: center;
  margin-bottom: 25px;
  //@media screen and (min-width: 992px) {
  //  margin-bottom: 0;
  //}
}
.footer-text--year {
  margin-inline: 10px;
}

.social-link {
  &:not(:last-child) {
    margin-right: 20px;
  }
  img {
    transition: all 250ms ease-out;
  }
  &:hover,
  &:focus {
    img {
      transform: scale(1.1);
    }
  }
}
