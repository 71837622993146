.dark-btn,
.light-btn,
.light-btn--small {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 45px;
  border-radius: 2px;
}

.dark-btn {
  position: relative;
  width: 100%;
  @media screen and (min-width: 576px) {
    width: 131px;
  }
  border: 1px solid black;
  height: 44px;
  background: $black;
  color: $white !important;
  transition: all 250ms ease;
&[disabled]{
    color: $grey!important;
    cursor: unset;
    pointer-events: none;
    &:hover, &:focus{
        color: $grey!important;
    }
}
  &:hover,
  &:focus {
    // transition: all 250ms ease;
    background: $white;
    color: $black !important;

    &::before {
      opacity: 1;
    }
  }
    &--purchase{
        padding: 10px;
        @media screen and (min-width: 576px) {
            padding: 10px 45px;
        }
    }
}

.light-btn {
  width: 100%;
  @media screen and (min-width: 576px) {
    width: 162px;
  }
  padding: 10px 30px;
  height: 44px;
  background: $white;
  color: $black;
  border: 1px solid $black;
  transition: all 250ms ease-out;

  &:hover,
  :focus {
    color: $black;
    opacity: 0.8;
  }
}

.light-btn--small {
  width: 100%;
  @media screen and (min-width: 576px) {
    width: 131px;
  }
  height: 44px;
  background: $white;
  color: $black;
  transition: all 250ms ease-out;

  &:hover,
  :focus {
    color: $black;
    opacity: 0.8;
  }
}

.small-btn {
  background: $black;
  color: $white;
  padding: 7px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &--white {
    background: $white;
    color: $black;
    border: 1px solid $black;
    padding: 7px 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
}

.counter-wrapper {
  max-width: 30px;
  max-height: 30px;
  line-height: 178%;
  &:hover {
    transform: scale(1.05);
    transition-duration: 0.5s;
  }
}

.search-btn {
  background-color: transparent;
  transition: all 250ms ease-out;
  span {
    transition: all 250ms ease-out;
    color: $grey;
  }
  img {
    transition: all 250ms ease-out;
  }
  &:hover,
  &:focus {
    span {
      color: $black;
    }
    img {
      transform: scale(1.1);
    }
  }

  &--white {
    transition: all 250ms ease-out;
    span {
      transition: all 250ms ease-out;
      color: $grey;
    }
    img {
      transition: all 250ms ease-out;
    }
    &:hover,
    &:focus {
      span {
        color: $white;
      }
      img {
        transform: scale(1.1);
      }
    }
  }
}

.btn-open-answer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  background-color: $black;
  color: $white;
  border-radius: 50%;
}

.btn-without-style {
  outline: 0;
  border: 0;
  background: transparent;
  &:hover {
    cursor: pointer;
    outline: 0;
    border: 0;
    background: transparent;
  }
}

.btn-100 {
  width: 100%;
}


.icon-animation:hover,
.delete-animation:hover {
  transition-duration: 0.3s;
  transform: scale(1.1);
}

.btn-heart:hover,
.btn-heart.active {
  fill: black;
  transition-duration: 0.7s;
  transition-timing-function: linear;
}
//prelouder
.loaders .loader {
    position: relative;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 100px;
    &::before {
        counter-increment: list;
        content: counter(list, decimal-leading-zero);
        position: absolute;
        top: -20px;
        left: -25px;
        font-size: 16px;
    }
}

.loader1 {
    span {
        display: block;
        width: 30px;
        height: 30px;
        border: 3px solid;
        border-radius: 50%;
        border-color: transparent white white;
        animation: cssload-spin 690ms infinite linear;
        @keyframes cssload-spin {
            100% {
                transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
    }
}
