input.input-error {
    border-color: #ff3d3d;
}

input.input-error:focus {
    border-color: #ff3d3d;
    box-shadow: 0 0 0 0.25rem rgb(255 61 61 / 25%);
}

input.input-success {
    border-color: #4aff3d;
}

input.input-success:focus {
    border-color: #4aff3d;
    box-shadow: 0 0 0 0.25rem rgba(126, 255, 61, 0.25);
}

.is-invalid {
    border-color: #ff3d3d !important;
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.1875rem) center;
    background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
