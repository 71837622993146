.black-link {
    font-family: montserrat-medium;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    color: $black;
    &:hover,
    :focus {
        color: $black;
    }
}
.black-light-link {
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: $black;
    &:hover,
    :focus {
        color: $black;
        text-decoration: underline;
    }
}
