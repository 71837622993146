.tab {
    .nav.nav-tabs {
        &.centered {
            justify-content: space-between;
            .nav-item {
                text-align: center;
            }
        }
        .nav-item {
            .nav-link {
                color: $black;
                border: none;
                border-bottom: 1px solid $dark-grey-contour;
                &.active {
                    border: none;
                    border-bottom: 1px solid $black;
                }
            }
        }
    }
}
