.notification {
    position: fixed;
    z-index: 10000;
    right: 17px;
    top: 118px;
    width: 100%;
    text-align: center;
    max-width: 460px;
    background: #FFFFFF;
    box-shadow: 0 15px 20px rgba(101, 100, 100, 0.15);
    display: none;
    border-radius: 10px;

    &-header {
        padding: 50px 16px 0;
    }

    &-body {
        padding: 0 8px 34px;
    }

    &-text,
    &-title {
        text-align: center;
        color: #161616;
    }

    &-title {
        margin-top: 30px;
    }

    &-icon {
        &-error {
            background: url(../../images/notification/error-notify.png);
            height: 49px;
            background-size: 11%;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: top;
        }

        &-success {
            background: url(../../images/notification/success-notify.png);
            height: 49px;
            background-size: 11%;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: top;
        }
    }

    .delimiter {
        margin: 20px auto;
    }
}

.notification-close {
    position: absolute;
    right: 10px;
    top: 10px;
}

@media (max-width: 771px) {
    .notification {
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

