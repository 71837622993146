body {
  counter-reset: counter;
}

.question-list {
  list-style: none;
  padding: 0;
}
.question-item {
  list-style: none;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid $black;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
.counter {
  display: flex;
  align-items: center;
}
.counter::before {
  counter-increment: counter;
  content: "0" counter(counter) " ";
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 170%;
  margin-right: 27px;
}
.question-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gray-text {
  color: $grey;
}
