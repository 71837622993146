// Body
$body-bg: #fefefe;
// Colors fill
$black: #010101;
$white: #ffffff;
$grey: #707070;
// Colors contour
$grey-contour: #ececec;
$dark-grey-contour: #dcdcdc;

// Typography
// $font-family-sans-serif: "Nunito", sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;
