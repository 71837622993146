.black-accent {
    background: $black;
}

.header-dropdown-menu.show {
    position: absolute;
    top: 22px !important;
}

.logo-header {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 262px;
}

.info {
    display: flex;
    align-items: center;
    padding: 4px 0px;
    @media screen and (min-width: 992px) {
        padding: 4px 0;
    }
    color: $white;
}

.info_button {
    margin-left: 4px;
    border-bottom: 1px solid $white;
    transition: all 250ms ease-out;

    &:hover,
    &:focus {
        opacity: 0.8;
        color: $white;
        // border-bottom: 1px solid $white;
    }
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0px 0px;
    @media screen and (min-width: 992px) {
        margin: 30px 0 25px 0;
    }
}

.header_wrap {
    display: flex;
    justify-content: end;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding-bottom: 28px;
}

.navigation {
    margin-top: 28px;
    display: flex;
    justify-content: center;
}

.navigation_item {
    font-weight: 400;

    &:hover a,
    &:focus a,
    &.active a {
        font-family: montserrat-medium;
        font-weight: 500;
        color: $black;
    }

    .navigation-dropdown {
        display: none;
        left: 0;
        box-shadow: 0px 160px 274px 10px rgba(0,0,0,0.35);

        //background: rgba(0, 0, 0, .5);
        z-index: 99;
        transition: display 500ms ease-in-out;
        a {
            font-family: montserrat-regular;
            font-weight: 400;

            &:hover, &.active {
                font-family: montserrat-medium;
                font-weight: 500;
            }
        }

        &:hover {

            display: flex;
        }

        &__content {
            min-height: 250px;
            height: min-content;

            ul {
                list-style: none;
            }
        }
    }

    &:hover .navigation-dropdown {
        display: flex;
    }
}

#mobile-category-accordion {
    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 0;
        }
    }
}

.btn-burger {
    color: rgba(0, 0, 0, 0) !important;
    border-color: transparent !important;
    border: none;
}

.search-list {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    overflow: scroll;
    max-width: 100%;
    overflow-x: hidden;
}

a {
    &:hover,
    &:focus {
        & .search-item-title {
            border-bottom: 1px solid $black;
        }
    }
}

.search-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding: 10px 0;
}

.search-item-image {
    width: 50px;
    height: auto;
    margin-right: 10px;
    object-fit: contain;
}

.search-wrapper {
    display: flex;
    flex-direction: column;
}

.search-item-title {
    transition: all 250ms ease;
}

.dropdown {
    display: flex;
}

.dropdown-menu {
    border-radius: 2px;
    color: $black;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    text-transform: lowercase;
    font-variant: small-caps;
}

.fixed-modal {
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    background-color: $black;
    border-radius: 100%;
    color: $white;

    &:hover,
    &:focus {
        color: $white;
    }
}

.modal-content {
    border-radius: 2px !important;
}

.btn-close {
    position: absolute;
    right: 16px;
    top: 21px;
}

.fixed-question {
    background-color: black;
}

.gray-accent {
    color: $grey;
}

.shop {
    position: relative;
}

.cart-notifi {
    z-index: 5;
    top: -13px;
    right: -24px;
    @media screen and (min-width: 772px) {
        top: -12px;
        right: -9px;

    }
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background: black;
    color: white;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 170%;

    &--white {
        top: -12px;
        right: -12px;
        background: white;
        color: black;
    }
}
