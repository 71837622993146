.notification {
  position: fixed;
  z-index: 10000;
  right: 17px;
  top: 118px;
  width: 100%;
  text-align: center;
  max-width: 460px;
  background: #FFFFFF;
  box-shadow: 0 15px 20px rgba(101, 100, 100, 0.15);
  display: none;
  border-radius: 10px;
}
.notification-header {
  padding: 50px 16px 0;
}
.notification-body {
  padding: 0 8px 34px;
}
.notification-text, .notification-title {
  text-align: center;
  color: #161616;
}
.notification-title {
  margin-top: 30px;
}
.notification-icon-error {
  background: url(../../images/notification/error-notify.png);
  height: 49px;
  background-size: 11%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
}
.notification-icon-success {
  background: url(../../images/notification/success-notify.png);
  height: 49px;
  background-size: 11%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
}
.notification .delimiter {
  margin: 20px auto;
}

.notification-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 771px) {
  .notification {
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
@font-face {
  font-family: "inter";
  src: url("../../fonts/inter/InterLight.woff") format("woff"), url("../../fonts/inter/InterLight.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-regular";
  src: url("../../fonts/montserrat/regular/Montserrat-Regular.woff") format("woff"), url("../../fonts/montserrat/regular/Montserrat-Regular.woff") format("woff2"), url("../../fonts/montserrat/regular/Montserrat-Regular.ttf") format("ttf"), url("../../fonts/montserrat/regular/Montserrat-Regular.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-medium";
  src: url("../../fonts/montserrat/medium/Montserrat-Medium.woff") format("woff"), url("../../fonts/montserrat/medium/Montserrat-Medium.woff") format("woff2"), url("../../fonts/montserrat/medium/Montserrat-Medium.ttf") format("ttf"), url("../../fonts/montserrat/medium/Montserrat-Medium.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-semibolt";
  src: url("../../fonts/montserrat/bolt/Montserrat-SemiBold.woff") format("woff"), url("../../fonts/montserrat/bolt/Montserrat-SemiBold.woff") format("woff2"), url("../../fonts/montserrat/bolt/Montserrat-SemiBold.ttf") format("ttf"), url("../../fonts/montserrat/bolt/Montserrat-SemiBold.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 170%;
  text-transform: uppercase;
  color: #010101;
}

.h1 {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 170%;
  text-transform: uppercase;
  color: #010101;
}

h2 {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px;
  line-height: 170%;
  text-transform: uppercase;
  color: #010101 !important;
  margin-bottom: 40px;
}

h3 {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  color: #010101;
}

h5.main-text-bold {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 170%;
  color: #010101;
  text-transform: uppercase;
}

.main-text {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #010101;
}

.main-text strong {
  font-family: montserrat-medium;
  font-weight: 500;
}

.main-text-bold {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #010101;
}

button,
.button-text {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
}

.footer-text {
  font-family: inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 170%;
}

.zoom-cursor {
  cursor: url("../../images/icons/zoom-in-icon.svg"), auto;
}

.container-image {
  position: relative;
}
.container-image .show {
  position: absolute;
  top: 0;
  opacity: 1;
  transition-duration: 0.6s;
}
.container-image .hide {
  position: absolute;
  top: 0;
  opacity: 0;
  transition-duration: 0.6s;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  margin-bottom: 0;
}
@media screen and (min-width: 1420px) {
  .image {
    height: 100%;
  }
}

.object-fit-cover {
  object-fit: cover;
}

p {
  margin: 0;
}

.container-big {
  max-width: 1440px;
}

.container-small {
  padding: 0 10px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
}

.container-slick {
  padding: 0 10px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 576px) {
  .container-slick {
    max-width: 530px;
  }
}
@media screen and (min-width: 768px) {
  .container-slick {
    max-width: 715px;
  }
}
@media screen and (min-width: 992px) {
  .container-slick {
    max-width: 940px;
  }
}
@media screen and (min-width: 1200px) {
  .container-slick {
    max-width: 1150px;
  }
}
@media screen and (min-width: 1400px) {
  .container-slick {
    max-width: 1345px;
  }
}
@media screen and (min-width: 1600px) {
  .container-slick {
    max-width: 1530px;
  }
}

.form-password {
  position: relative;
}
.form-password .show-password {
  width: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 14px;
}

.open-password {
  position: relative;
}
.open-password::after {
  position: absolute;
  content: "";
  top: 23px;
  right: 16px;
  width: 19px;
  height: 2px;
  z-index: 0;
  background-color: #000000;
  transform: translateY(-50%) rotate(45deg);
}

input[type=text],
input[type=number],
input[type=tel],
input[type=password],
input[type=email] {
  border-bottom: 1px solid #dfdfdf;
  background-color: transparent;
  min-height: 44px;
  max-height: 44px;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}
input[type=text]:focus-visible,
input[type=number]:focus-visible,
input[type=tel]:focus-visible,
input[type=password]:focus-visible,
input[type=email]:focus-visible {
  outline: none;
  border-bottom: 1px solid #010101;
}
input[type=text]::placeholder,
input[type=number]::placeholder,
input[type=tel]::placeholder,
input[type=password]::placeholder,
input[type=email]::placeholder {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

.white-background {
  background-color: #ffffff;
}

.search-icon-input {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 30px;
}

textarea:disabled {
  border: 1px solid #dfdfdf;
  opacity: 0.7;
}
textarea:disabled:hover {
  cursor: not-allowed;
}

.form-check-input.disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

textarea {
  border: 1px solid #dfdfdf;
  border-radius: 7px;
  padding-left: 30px;
  padding-top: 10px;
  background-color: transparent;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}
textarea:focus-visible {
  outline: none;
  border: 1px solid #010101;
}
textarea::placeholder {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

input[class=search] {
  border: 1px solid #010101;
  border-radius: 7px;
  background-color: transparent;
  min-height: 44px;
  padding: 0 60px;
  width: 100%;
  max-height: 44px;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}
input[class=search]:focus-visible {
  outline: none;
  border-color: #010101;
}

.form-select-custom {
  min-height: 44px;
  max-height: 44px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #010101;
  border-radius: 0;
  background-color: transparent;
}
.form-select-custom:focus-visible {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #010101;
}

.form-check-input:focus {
  border-color: #010101;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked[type=radio] {
  background-size: 18px;
}

.question-input-group input,
.question-input-group textarea {
  width: 100%;
  max-width: 604px;
}

input.input-error {
  border-color: #ff3d3d;
}

input.input-error:focus {
  border-color: #ff3d3d;
  box-shadow: 0 0 0 0.25rem rgba(255, 61, 61, 0.25);
}

input.input-success {
  border-color: #4aff3d;
}

input.input-success:focus {
  border-color: #4aff3d;
  box-shadow: 0 0 0 0.25rem rgba(126, 255, 61, 0.25);
}

.show-hide-icon {
  position: absolute;
  top: -3px;
  right: 27px;
  transform: translateY(50%);
  z-index: 1;
}

.custom-checkbox {
  position: absolute;
  top: 0;
  right: 30px;
  transform: translateY(50%);
  display: inline-block;
  width: 20px;
  height: 20px;
  z-index: -1;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.arrow {
  position: absolute;
  top: 44px;
  right: 0;
}

.location-input {
  position: relative;
}

label {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #010101;
  margin-bottom: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-7 {
  margin-right: 7px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-227 {
  margin-right: 227px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-19 {
  margin-right: 19px;
}

.margin-right-37 {
  margin-right: 37px;
}

.margin-bottom-150 {
  margin-bottom: 150px;
}

.margin-bottom-120 {
  margin-bottom: 120px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-9 {
  margin-bottom: 9px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-19 {
  margin-top: 19px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-120 {
  margin-top: 120px;
}

.margin-5 {
  margin: 0 5px;
}

.image-auto {
  height: auto;
}

.padding-40 {
  padding: 40px;
}

.dark-btn,
.light-btn,
.light-btn--small {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 45px;
  border-radius: 2px;
}

.dark-btn {
  position: relative;
  width: 100%;
  border: 1px solid black;
  height: 44px;
  background: #010101;
  color: #ffffff !important;
  transition: all 250ms ease;
}
@media screen and (min-width: 576px) {
  .dark-btn {
    width: 131px;
  }
}
.dark-btn[disabled] {
  color: #707070 !important;
  cursor: unset;
  pointer-events: none;
}
.dark-btn[disabled]:hover, .dark-btn[disabled]:focus {
  color: #707070 !important;
}
.dark-btn:hover, .dark-btn:focus {
  background: #ffffff;
  color: #010101 !important;
}
.dark-btn:hover::before, .dark-btn:focus::before {
  opacity: 1;
}
.dark-btn--purchase {
  padding: 10px;
}
@media screen and (min-width: 576px) {
  .dark-btn--purchase {
    padding: 10px 45px;
  }
}

.light-btn {
  width: 100%;
  padding: 10px 30px;
  height: 44px;
  background: #ffffff;
  color: #010101;
  border: 1px solid #010101;
  transition: all 250ms ease-out;
}
@media screen and (min-width: 576px) {
  .light-btn {
    width: 162px;
  }
}
.light-btn:hover,
.light-btn :focus {
  color: #010101;
  opacity: 0.8;
}

.light-btn--small {
  width: 100%;
  height: 44px;
  background: #ffffff;
  color: #010101;
  transition: all 250ms ease-out;
}
@media screen and (min-width: 576px) {
  .light-btn--small {
    width: 131px;
  }
}
.light-btn--small:hover,
.light-btn--small :focus {
  color: #010101;
  opacity: 0.8;
}

.small-btn {
  background: #010101;
  color: #ffffff;
  padding: 7px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}
.small-btn--white {
  background: #ffffff;
  color: #010101;
  border: 1px solid #010101;
  padding: 7px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.counter-wrapper {
  max-width: 30px;
  max-height: 30px;
  line-height: 178%;
}
.counter-wrapper:hover {
  transform: scale(1.05);
  transition-duration: 0.5s;
}

.search-btn {
  background-color: transparent;
  transition: all 250ms ease-out;
}
.search-btn span {
  transition: all 250ms ease-out;
  color: #707070;
}
.search-btn img {
  transition: all 250ms ease-out;
}
.search-btn:hover span, .search-btn:focus span {
  color: #010101;
}
.search-btn:hover img, .search-btn:focus img {
  transform: scale(1.1);
}
.search-btn--white {
  transition: all 250ms ease-out;
}
.search-btn--white span {
  transition: all 250ms ease-out;
  color: #707070;
}
.search-btn--white img {
  transition: all 250ms ease-out;
}
.search-btn--white:hover span, .search-btn--white:focus span {
  color: #ffffff;
}
.search-btn--white:hover img, .search-btn--white:focus img {
  transform: scale(1.1);
}

.btn-open-answer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  background-color: #010101;
  color: #ffffff;
  border-radius: 50%;
}

.btn-without-style {
  outline: 0;
  border: 0;
  background: transparent;
}
.btn-without-style:hover {
  cursor: pointer;
  outline: 0;
  border: 0;
  background: transparent;
}

.btn-100 {
  width: 100%;
}

.icon-animation:hover,
.delete-animation:hover {
  transition-duration: 0.3s;
  transform: scale(1.1);
}

.btn-heart:hover,
.btn-heart.active {
  fill: black;
  transition-duration: 0.7s;
  transition-timing-function: linear;
}

.loaders .loader {
  position: relative;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
}
.loaders .loader::before {
  counter-increment: list;
  content: counter(list, decimal-leading-zero);
  position: absolute;
  top: -20px;
  left: -25px;
  font-size: 16px;
}

.loader1 span {
  display: block;
  width: 30px;
  height: 30px;
  border: 3px solid;
  border-radius: 50%;
  border-color: transparent white white;
  animation: cssload-spin 690ms infinite linear;
}
@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.breadcrumbs {
  margin-bottom: 40px;
}

.active-breadcrumb,
.previos-breadcrumb {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #707070;
  opacity: 0.5;
  padding: 0 2px;
  transition: all 250ms ease-out;
}
.active-breadcrumb:hover, .active-breadcrumb:focus,
.previos-breadcrumb:hover,
.previos-breadcrumb:focus {
  color: black;
  opacity: 1;
}

.active-breadcrumb {
  color: black;
  opacity: 1;
}

.slider .slide-container {
  margin-bottom: 15px;
}
@media screen and (min-width: 576px) {
  .slider .slide-container {
    margin-bottom: 0;
  }
}

.container-slide-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
@media screen and (min-width: 370px) {
  .container-slide-item {
    max-width: 295px;
  }
}

.card-container {
  width: calc(100%/2 - 10px);
  margin-inline: 5px;
}
@media screen and (min-width: 769px) {
  .card-container {
    width: calc(100%/3 - 20px);
    margin-inline: 10px;
  }
}
@media screen and (min-width: 992px) {
  .card-container {
    margin-inline: 0;
  }
}
@media screen and (min-width: 1200px) {
  .card-container {
    width: calc(100%/3);
    margin-inline: 0;
  }
}
@media screen and (min-width: 1200px) {
  .card-container {
    width: calc(100%/4);
  }
}

.container-image {
  border-bottom: 1px solid #010101;
  height: 360px;
  margin-bottom: 15px;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .container-image {
    height: 250px;
  }
}
.card-action-img {
  width: 29px;
  height: auto;
}

.image-card {
  display: block;
  height: 95%;
  max-height: inherit;
  margin-bottom: 15px;
  object-fit: contain;
  width: 100%;
}

.card-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.text {
  width: 100%;
  padding: 0 10px;
  text-align: left;
}

.card-black-border:hover {
  border: 1px solid #010101 !important;
}

.price {
  font-size: 18px;
}

.card-btn {
  width: 100%;
  background: #010101;
  color: #ffffff;
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  transition: all 250ms ease-out;
}
.card-btn:hover, .card-btn:focus {
  opacity: 0.7;
  color: #ffffff;
}

.card-btn {
  opacity: 0;
  transition: all 250ms ease-out;
}
@media screen and (max-width: 992px) {
  .card-btn {
    opacity: 1;
  }
}
.bordered:hover ~ .card-btn, .bordered:focus ~ .card-btn, .card-box:hover ~ .card-btn, .card-box:focus ~ .card-btn {
  opacity: 1;
}

.slick-track {
  margin-left: 0;
  margin-right: 0;
}

.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  color: black;
  transition: all 250ms ease-out;
}
@media screen and (max-width: 768px) {
  .slick-prev,
.slick-next {
    top: 30%;
  }
}
.slick-prev::before,
.slick-next::before {
  transition: all 250ms ease-out;
  color: inherit;
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
@media screen and (max-width: 575px) {
  .slick-prev::before,
.slick-next::before {
    display: none;
  }
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: black;
}
.slick-prev:hover::before, .slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
  transition: all 250ms ease-out;
  color: inherit;
  background-color: black;
  border-color: black;
}

.slick-prev {
  left: -34px;
}
.slick-prev::before {
  background: url("/images/icons/arrow-black.svg") no-repeat center;
}
.slick-prev:hover, .slick-prev:focus {
  color: black;
}
.slick-prev:hover::before, .slick-prev:focus::before {
  background: url("/images/icons/arrow-white-left.svg") no-repeat center;
  color: inherit;
  background-color: black;
  border-color: black;
}

.slick-next {
  right: -34px;
}
.slick-next::before {
  background: url("/images/icons/arrow-black-right.svg") no-repeat center;
}
.slick-next:hover, .slick-next:focus {
  color: black;
}
.slick-next:hover::before, .slick-next:focus::before {
  background: url("/images/icons/arrow-slider.svg") no-repeat center;
  color: inherit;
  background-color: black;
  border-color: black;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-list {
  z-index: 3;
}

.bordered {
  transition: all 250ms ease-out;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}
@media screen and (max-width: 992px) {
  .bordered {
    border-right: 1px solid black;
    border-left: 1px solid black;
  }
}

.wrap-btn {
  width: 100%;
  height: 100%;
}

.slick-slide {
  transition: all 250ms ease-out;
}
.slick-slide:hover .bordered, .slick-slide:focus .bordered {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.card-box {
  transition: all 250ms ease-out;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  padding: 0 10px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-inline: 0;
}
@media screen and (max-width: 992px) {
  .card-box {
    width: 100%;
    border-right: 1px solid black;
    border-left: 1px solid black;
    margin-inline: 10px;
  }
}
.card-container:hover .card-box, .card-container:focus .card-box {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.slick-dots {
  position: absolute;
  top: 10%;
  right: 0;
}

.slick-dots li button {
  position: relative;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border: 1px solid var(--secondary-text-color);
  border-radius: 50%;
  font-size: 0;
  outline-style: none;
}

body {
  counter-reset: btn;
}

.slick-dots {
  top: -17%;
}

.slider-page {
  overflow: hidden;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  width: 168px;
}
@media screen and (min-width: 992px) {
  .slider-page {
    width: 292px;
  }
}

.black-accent {
  background: #010101;
}

.header-dropdown-menu.show {
  position: absolute;
  top: 22px !important;
}

.logo-header {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 262px;
}

.info {
  display: flex;
  align-items: center;
  padding: 4px 0px;
  color: #ffffff;
}
@media screen and (min-width: 992px) {
  .info {
    padding: 4px 0;
  }
}

.info_button {
  margin-left: 4px;
  border-bottom: 1px solid #ffffff;
  transition: all 250ms ease-out;
}
.info_button:hover, .info_button:focus {
  opacity: 0.8;
  color: #ffffff;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0px 0px;
}
@media screen and (min-width: 992px) {
  .header {
    margin: 30px 0 25px 0;
  }
}

.header_wrap {
  display: flex;
  justify-content: end;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding-bottom: 28px;
}

.navigation {
  margin-top: 28px;
  display: flex;
  justify-content: center;
}

.navigation_item {
  font-weight: 400;
}
.navigation_item:hover a, .navigation_item:focus a, .navigation_item.active a {
  font-family: montserrat-medium;
  font-weight: 500;
  color: #010101;
}
.navigation_item .navigation-dropdown {
  display: none;
  left: 0;
  box-shadow: 0px 160px 274px 10px rgba(0, 0, 0, 0.35);
  z-index: 99;
  transition: display 500ms ease-in-out;
}
.navigation_item .navigation-dropdown a {
  font-family: montserrat-regular;
  font-weight: 400;
}
.navigation_item .navigation-dropdown a:hover, .navigation_item .navigation-dropdown a.active {
  font-family: montserrat-medium;
  font-weight: 500;
}
.navigation_item .navigation-dropdown:hover {
  display: flex;
}
.navigation_item .navigation-dropdown__content {
  min-height: 250px;
  height: min-content;
}
.navigation_item .navigation-dropdown__content ul {
  list-style: none;
}
.navigation_item:hover .navigation-dropdown {
  display: flex;
}

#mobile-category-accordion ul {
  list-style: none;
  padding: 0;
}
#mobile-category-accordion ul li {
  margin: 0;
}

.btn-burger {
  color: rgba(0, 0, 0, 0) !important;
  border-color: transparent !important;
  border: none;
}

.search-list {
  list-style-type: none;
  /* Remove bullets */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margins */
  overflow: scroll;
  max-width: 100%;
  overflow-x: hidden;
}

a:hover .search-item-title, a:focus .search-item-title {
  border-bottom: 1px solid #010101;
}

.search-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding: 10px 0;
}

.search-item-image {
  width: 50px;
  height: auto;
  margin-right: 10px;
  object-fit: contain;
}

.search-wrapper {
  display: flex;
  flex-direction: column;
}

.search-item-title {
  transition: all 250ms ease;
}

.dropdown {
  display: flex;
}

.dropdown-menu {
  border-radius: 2px;
  color: #010101;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  text-transform: lowercase;
  font-variant: small-caps;
}

.fixed-modal {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  background-color: #010101;
  border-radius: 100%;
  color: #ffffff;
}
.fixed-modal:hover, .fixed-modal:focus {
  color: #ffffff;
}

.modal-content {
  border-radius: 2px !important;
}

.btn-close {
  position: absolute;
  right: 16px;
  top: 21px;
}

.fixed-question {
  background-color: black;
}

.gray-accent {
  color: #707070;
}

.shop {
  position: relative;
}

.cart-notifi {
  z-index: 5;
  top: -13px;
  right: -24px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: black;
  color: white;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 170%;
}
@media screen and (min-width: 772px) {
  .cart-notifi {
    top: -12px;
    right: -9px;
  }
}
.cart-notifi--white {
  top: -12px;
  right: -12px;
  background: white;
  color: black;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  width: 100%;
  margin-bottom: 100px;
}
@media screen and (min-width: 768px) {
  .hero {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    max-width: 1600px;
    margin-bottom: 170px;
  }
}
.hero--catalog {
  margin-bottom: 62px;
}

.hero-section {
  width: 100%;
  height: 375px;
  background-color: #010101;
}
@media screen and (min-width: 768px) {
  .hero-section {
    width: 50%;
    height: 479px;
  }
}
@media screen and (min-width: 1420px) {
  .hero-section {
    height: 479px;
  }
}
.hero-section--text {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10% 10px;
}
@media screen and (min-width: 768px) {
  .hero-section--text {
    padding: 0 10% 0 10%;
  }
}
@media screen and (min-width: 1420px) {
  .hero-section--text {
    padding: 0 124px 0 124px;
  }
}
.hero-section--text p {
  max-height: 220px;
  overflow: hidden;
  margin-bottom: 30px;
  margin-right: 0;
  color: #ffffff;
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}
.hero-section--text h1 {
  margin-bottom: 20px;
  color: #ffffff;
}

.hero-section-catalog {
  width: 100vw;
  height: 479px;
}

.section {
  max-width: 1600px;
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .section {
    flex-direction: row;
    margin-bottom: 170px;
  }
}
.section--gallery {
  width: 100vw;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .section--gallery {
    flex-direction: row;
    max-height: 532px;
  }
}

.section-title {
  text-transform: uppercase;
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-column-gap: 20px;
    grid-row-gap: 33px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1420px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.box1,
.box2 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .box1,
.box2 {
    margin-bottom: 0;
  }
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery-image-container {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .gallery-image-container {
    width: 33.3%;
  }
}

.wrapper-box {
  width: 100%;
  height: 375px;
}
@media screen and (min-width: 768px) {
  .wrapper-box {
    width: 100%;
    height: 401px;
  }
}

.text-position {
  padding: 0 0 0 10px;
}
@media screen and (min-width: 768px) {
  .text-position {
    text-align: center;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .text-position {
    padding: 0;
  }
}

.catalog-link {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
@media screen and (min-width: 768px) {
  .catalog-link {
    width: 50vw;
  }
}
@media screen and (max-width: 767px) {
  .catalog-link:not(:last-child) {
    margin-bottom: 100px;
  }
}
.catalog-link .section-title {
  transition: all 250ms ease-out;
}
.catalog-link:hover .section-title, .catalog-link:focus .section-title {
  color: black;
  transform: scale(1.1);
}

.about-wrapper {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .about-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

.about-description {
  text-align: justify;
  width: 100%;
}
.about-description p {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #010101;
  max-height: 280px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .about-description {
    width: 45%;
  }
}
@media screen and (max-width: 767px) {
  .about-description {
    margin-bottom: 40px;
  }
}

.about-image {
  width: 100%;
  height: 375px;
}
@media screen and (min-width: 768px) {
  .about-image {
    width: 50%;
  }
}

body {
  counter-reset: counter;
}

.question-list {
  list-style: none;
  padding: 0;
}

.question-item {
  list-style: none;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #010101;
}
.question-item:not(:last-child) {
  margin-bottom: 40px;
}

.counter {
  display: flex;
  align-items: center;
}

.counter::before {
  counter-increment: counter;
  content: "0" counter(counter) " ";
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 170%;
  margin-right: 27px;
}

.question-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gray-text {
  color: #707070;
}

.footer {
  display: flex;
  justify-content: center;
}

.footer-container {
  padding: 10px 10px;
}
@media screen and (min-width: 576px) {
  .footer-container {
    padding: 25px 50px;
  }
}
@media screen and (min-width: 1420px) {
  .footer-container {
    padding: 25px 10px;
  }
}

.footer-wrapper {
  display: block;
}
@media screen and (min-width: 992px) {
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: end;
  margin-bottom: 0;
  align-items: flex-start;
}

.contacts-item {
  font-family: "montserrat-regular";
  transition: all 250ms ease-out;
  border-bottom: 1px solid transparent;
}
.contacts-item:hover, .contacts-item:focus {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

.policy {
  text-align: center;
  color: #ffffff;
}

.centered-block {
  margin-left: 60px;
}

.footer_logo {
  display: flex;
  align-items: center;
  align-self: baseline;
  justify-content: center;
  margin-bottom: 25px;
}

.footer-text--year {
  margin-inline: 10px;
}

.social-link:not(:last-child) {
  margin-right: 20px;
}
.social-link img {
  transition: all 250ms ease-out;
}
.social-link:hover img, .social-link:focus img {
  transform: scale(1.1);
}

.modal .custom-modal-content {
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(180, 180, 180, 0.25);
  border-radius: 25px;
}
.modal .custom-modal-content .modal-header {
  border-bottom: none;
}
.modal .custom-modal-content .custom-modal-body {
  padding: 0 30px;
}
@media (min-width: 461px) {
  .modal .custom-modal-content .custom-modal-body {
    padding: 0 50px;
  }
}
@media (min-width: 500px) {
  .modal .custom-modal-content .custom-modal-body {
    padding: 0 100px;
  }
}
@media (min-width: 992px) {
  .modal .custom-modal-content .custom-modal-body {
    padding: 0 100px;
  }
}
.modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .modal-input-group {
  text-align: start;
}
.modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .modal-input-group input[type=text], .modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .modal-input-group input[type=number], .modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .modal-input-group input[type=password], .modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .modal-input-group input[type=datetime-local], .modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .modal-input-group textarea, .modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .modal-input-group button {
  width: 100%;
  max-width: 396px;
}
.modal .custom-modal-content .custom-modal-body .wrapper-basic-modal form .btn-submit-modal {
  width: 100%;
  max-width: 396px;
}
.modal .preloader-modal {
  filter: brightness(0.8);
  transition-duration: 0.5s;
  cursor: progress !important;
}
.modal .preloader-modal .btn-submit-modal {
  cursor: not-allowed !important;
}

.modal-basic {
  width: 100%;
  max-width: 595px;
}
@media (max-width: 576px) {
  .modal-basic {
    width: 96%;
  }
}

.tab .nav.nav-tabs.centered {
  justify-content: space-between;
}
.tab .nav.nav-tabs.centered .nav-item {
  text-align: center;
}
.tab .nav.nav-tabs .nav-item .nav-link {
  color: #010101;
  border: none;
  border-bottom: 1px solid #dcdcdc;
}
.tab .nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-bottom: 1px solid #010101;
}

.black-link {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
  color: #010101;
}
.black-link:hover,
.black-link :focus {
  color: #010101;
}

.black-light-link {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #010101;
}
.black-light-link:hover,
.black-light-link :focus {
  color: #010101;
  text-decoration: underline;
}

input.input-error {
  border-color: #ff3d3d;
}

input.input-error:focus {
  border-color: #ff3d3d;
  box-shadow: 0 0 0 0.25rem rgba(255, 61, 61, 0.25);
}

input.input-success {
  border-color: #4aff3d;
}

input.input-success:focus {
  border-color: #4aff3d;
  box-shadow: 0 0 0 0.25rem rgba(126, 255, 61, 0.25);
}

.is-invalid {
  border-color: #ff3d3d !important;
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

html,
body,
#app {
  height: max-content;
  display: flex;
  flex-direction: column;
  min-height: 105vh;
  overflow-x: hidden;
  justify-content: space-between;
}

.fixed-question {
  border: 1px solid #fff;
  border-radius: 100%;
  position: fixed;
  right: 1%;
  bottom: 3%;
  z-index: 3;
}

.dropdown-item:active {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}