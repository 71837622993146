@import "variables";

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="password"],
input[type="email"] {
    border-bottom: 1px solid #dfdfdf;
    background-color: transparent;
    min-height: 44px;
    max-height: 44px;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;

    &:focus-visible {
        outline: none;
        border-bottom: 1px solid $black;
    }

    &::placeholder {
        font-family: montserrat-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
    }
}

.white-background {
    background-color: $white;
}

.search-icon-input {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 30px;
}

textarea:disabled {
    border: 1px solid #dfdfdf;
    opacity: 0.7;

    &:hover {
        cursor: not-allowed;
    }
}

.form-check-input.disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}

textarea {
    border: 1px solid #dfdfdf;
    border-radius: 7px;
    padding-left: 30px;
    padding-top: 10px;
    background-color: transparent;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    &:focus-visible {
        outline: none;
        border: 1px solid $black;
    }

    &::placeholder {
        font-family: montserrat-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
    }
}

input[class="search"] {
    border: 1px solid $black;
    border-radius: 7px;
    background-color: transparent;
    min-height: 44px;
    padding: 0 60px;
    width: 100%;
    max-height: 44px;
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;

    &:focus-visible {
        outline: none;
        border-color: $black;
    }
}

.form-select-custom {
    min-height: 44px;
    max-height: 44px;
    width: 100%;
    border: none;
    border-bottom: 1px solid $black;
    border-radius: 0;
    background-color: transparent;

    &:focus-visible {
        outline: none;
        border-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid $black;
    }
}

.form-check-input {
    &:focus {
        border-color: $black;
        outline: 0;
        box-shadow: none;
    }

    &:checked[type="radio"] {
        background-size: 18px;
    }
}

.question-input-group {
    input,
    textarea {
        width: 100%;
        max-width: 604px;
    }
}

input.input-error {
    border-color: #ff3d3d;
}

input.input-error:focus {
    border-color: #ff3d3d;
    box-shadow: 0 0 0 0.25rem rgb(255 61 61 / 25%);
}

input.input-success {
    border-color: #4aff3d;
}

input.input-success:focus {
    border-color: #4aff3d;
    box-shadow: 0 0 0 0.25rem rgba(126, 255, 61, 0.25);
}

.show-hide-icon {
    position: absolute;
    top: -3px;
    right: 27px;
    transform: translateY(50%);
    z-index: 1;
}

.custom-checkbox {
    position: absolute;
    top: 0;
    right: 30px;
    transform: translateY(50%);
    display: inline-block;
    width: 20px;
    height: 20px;
    z-index: -1;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.arrow {
    position: absolute;
    top: 44px;
    right: 0;
}

.location-input {
    position: relative;
}

label {
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: $black;
    margin-bottom: 15px;
}
