h1,
h2,
h3 {
  margin: 0;
}
h1 {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 170%;
  text-transform: uppercase;
  color: $black;
}
.h1 {
    font-family: montserrat-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 170%;
    text-transform: uppercase;
    color: $black;
}

h2 {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500!important;
  font-size: 24px;
  line-height: 170%;
  text-transform: uppercase;
  color: $black!important;
  margin-bottom: 40px;
}

h3 {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
  color: $black;
}
h5.main-text-bold {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 170%;
  color: $black;
  text-transform: uppercase;
}
.main-text {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: $black;
}

.main-text {
    strong {
        font-family: montserrat-medium;
        font-weight: 500;
    }
}

.main-text-bold {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: $black;
}

button,
.button-text {
  font-family: montserrat-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
}

.footer-text {
  font-family: inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 170%;
}

