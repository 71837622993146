.modal {
    .custom-modal-content {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(180, 180, 180, 0.25);
        border-radius: 25px;
        .modal-header {
            border-bottom: none;
        }
        .custom-modal-body {
            padding: 0 30px;
            @media (min-width: 461px) {
                    padding: 0 50px;
                }
            @media (min-width: 500px) {
                    padding: 0 100px;
            }
            @media (min-width: 992px) {
                padding: 0 100px;
                }
            .wrapper-basic-modal {
                form {
                    .modal-input-group {
                        text-align: start;
                        input[type="text"], input[type="number"], input[type="password"], input[type="datetime-local"], textarea, button {
                            width: 100%;
                            max-width: 396px;
                        }
                    }
                    .btn-submit-modal {
                        width: 100%;
                        max-width: 396px;
                    }
                }
            }
        }

    }
    .preloader-modal {
        filter: brightness(0.8);
        transition-duration: 0.5s;
        cursor: progress!important;
        .btn-submit-modal {
            cursor: not-allowed!important;
        }
    }
}

.modal-basic {
    @media (max-width:576px) {
     width: 96%;
    }
    width: 100%;
    max-width: 595px;
}
