.wrapper-box {
  // position: relative;
  width: 100%;
  height: 375px;
  @media screen and (min-width: 768px) {
    width: 100%;
    //max-width: 720px;
    height: 401px;
  }
}
.text-position {
  padding: 0 0 0 10px;
  @media screen and (min-width: 768px) {
    text-align: center;
    // width: 40%;
    padding: 0;
  }
  @media screen and (min-width: 768px) {
    // width: 50%;
    padding: 0;
  }
}
.catalog-link {
  display: flex;
  flex-direction: column;

  width: 100vw;
  @media screen and (min-width: 768px) {
    width: 50vw;
    //max-width: 720px;
  }
  @media screen and (max-width: 767px) {
    &:not(:last-child) {
      margin-bottom: 100px;
    }
  }
  & .section-title {
    transition: all 250ms ease-out;
  }
  &:hover,
  &:focus {
    & .section-title {
      color: black;
      transform: scale(1.1);
    }
  }
}
