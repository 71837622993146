.about-wrapper {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
.about-description {
  p {
    font-family: montserrat-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: $black;
    max-height: 280px;
    overflow: hidden;
  }
  text-align: justify;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 45%;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
}
.about-image {
  width: 100%;
  height: 375px;
  @media screen and (min-width: 768px) {
    width: 50%;
    //height: 271px;
    //max-width: 604px;
  }
}
