.breadcrumbs {
  margin-bottom: 40px;
}
.active-breadcrumb,
.previos-breadcrumb {
  font-family: montserrat-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #707070;
  opacity: 0.5;
  padding: 0 2px;
  transition: all 250ms ease-out;
  &:hover,
  &:focus {
    color: black;
    opacity: 1;
  }
}
.active-breadcrumb {
  color: black;
  opacity: 1;
}
