.section {
    max-width: 1600px;
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 170px;
  }
  &--gallery {
    width: 100vw;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
        max-height: 532px;
    }
  }
}

.section-title {
  text-transform: uppercase;
  margin-bottom: 40px;
}
.grid {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (min-width: 1420px) {
      grid-template-columns: repeat(4, 1fr);
    }
    grid-auto-rows: minmax(100px, auto);
    grid-column-gap: 20px;
    grid-row-gap: 33px;
  }
}
.box1,
.box2 {
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}
.box1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.gallery-image-container {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 33.3%;
  }
}
