$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

.zoom-cursor {
    cursor: url('../../images/icons/zoom-in-icon.svg'), auto;
}

.container-image {
    position: relative;
    .show {
        position: absolute;
        top: 0;
        opacity: 1;
        transition-duration: 0.6s;
    }
    .hide {
        position: absolute;
        top: 0;
        opacity: 0;
        transition-duration: 0.6s;
    }
}

.image {
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1420px) {
        height: 100%;
    }
    display: block;
    margin-bottom: 0;
}

.object-fit-cover {
    object-fit: cover;
}

p {
    margin: 0;
}

.container-big {
    max-width: 1440px;
}

.container-small {
    padding: 0 10px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1600px;
    //@media screen and (min-width: 576px) {
    //    // padding: 0 50px;
    //    width: 576px;
    //}
    //
    //@media screen and (min-width: 768px) {
    //    width: 768px;
    //}
    //@media screen and (min-width: 992px) {
    //    // padding: 0 50px;
    //    width: 992px;
    //}
    //@media screen and (min-width: 1200px) {
    //    width: 1200px;
    //    // max-width: 100vw;
    //}
    //
    //@media screen and (min-width: 1400px) {
    //    width: 1228px;
    //}
}
.container-slick{
    padding: 0 10px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @media screen and (min-width: 576px) {
        // padding: 0 50px;
        max-width: 530px;
    }

    @media screen and (min-width: 768px) {
        max-width: 715px;
    }
    @media screen and (min-width: 992px) {
        // padding: 0 50px;
        max-width: 940px;
    }
    @media screen and (min-width: 1200px) {
        max-width: 1150px;
    }

    @media screen and (min-width: 1400px) {
        max-width: 1345px;
    }
    @media screen and (min-width: 1600px) {
        max-width: 1530px;
    }

}
.form-password{
    position: relative;
    & .show-password {
        width: 20px;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        right: 16px;
        top: 14px;
    }
}
.open-password {
    position: relative;
    &::after {
        position: absolute;
        content: "";
        top: 23px;
        right: 16px;
        width: 19px;
        height: 2px;
        z-index: 0;
        background-color: #000000;
        transform: translateY(-50%) rotate(45deg);
    }
}
